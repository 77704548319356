import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ValidationRule } from 'vee-validate/dist/types/types';
import { axios, createApiUrl } from './request';

export const APP_STORE_URL = 'https://apps.apple.com/cn/app/%E8%83%BD%E9%93%BE%E4%BC%81%E6%9C%8D/id1569327762';
export const DEFAULT_APK_URL = 'https://tykj-prod-public-read-1305297355.cos.ap-beijing.myqcloud.com/nl-release-1.0.0-20210526-11_03.apk';

export type VersionRes = {
  id: number;
  url: string;
  appName: string;
  appType: string;
  downChannel: string;
  forceUpdate: boolean;
  needUpdate: boolean;
  publishTime: string;
  updateLog: string;
  version: string;
};

export function extendInviteRule(): void {
  extend('userNameRequired', {
    ...required,
    message: '请输入用户名',
  });

  extend('phoneRequired', {
    ...required,
    message: '请输入手机号',
  });

  extend('isMobile', {
    validate(value, args) {
      return /^1[3-9]\d{9}$/.test(value);
    },
    message: '手机号格式不正确',
  });

  extend('codeRequired', {
    ...required,
    message: '请输入验证码',
  });

  extend('isCode', {
    validate(value, args) {
      return /^\d{6}$/.test(value);
    },
    message: '验证码格式不正确',
  });
}

export const InviteMixins = {
  data() {
    return {
      inviteDetail: {
        examineState: 'NEED',
        enterpriseName: '',
        state: 'OPEN',
      },
    };
  },
  methods: {
    async loadInviteDetail(this: any, code: string) {
      const res = await axios.get(createApiUrl('/newlinkSass/invitation/invitation-find-by-code-detail'), { params: { code } });
      this.inviteDetail = res.data;
    },
  },
};

export const getParams = (val?: string) => {
  const url = val || window.location.href;
  if (url.indexOf('?') !== -1) {
    const obj: {[x: string]: string;} = {};
    const arr = url.slice(url.indexOf('?') + 1).split('&');
    arr.forEach(item => {
      const param = item.split('=');
      obj[param[0]] = param[1];
    });
    return obj;
  }
  return {};
};
