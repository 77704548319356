import Vue from 'vue';
import Page from './Page.vue';

const ua = window.navigator.userAgent;
if (ua.indexOf('Android') !== -1 || !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
  window.location.href = `/pages/invite-mobile.html${window.location.search}`;
} else {
  (new Vue({
    render(h) {
      return h(Page);
    },
  })).$mount('#app');
}
