
import { ValidationProvider, ValidationObserver, extend, validate } from 'vee-validate';
import { mobileCopyright, pcCopyright } from 'nl-illuminate/utils/constants';
import Vue from 'vue';

import { CountdownMixins } from '@/components/countDown';
import { WebModal } from '@/components/webModal';

import { axios, createApiUrl } from '../utils/request';
import { extendInviteRule, InviteMixins } from '../utils/utils';

extendInviteRule();
const defaultFormData = {
  userName: '',
  phone: '',
  verificationCode: '',
  jobNumber: '',
  invitationCode: '',
};

export default Vue.extend({
  name: 'Page',
  mixins: [InviteMixins, CountdownMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    WebModal,
  },
  data() {
    return {
      formData: defaultFormData,

      phoneError: '',
      codeError: '',
      codeSending: false,
      message: '',
      submiting: false,
      copyright: pcCopyright,
    };
  },
  computed: {
    errorVisible(this: any) {
      return !this.formData.invitationCode || this.inviteDetail.state !== 'OPEN';
    },
  },
  methods: {
    clearInputValue(type: keyof typeof defaultFormData) {
      this.formData[type] = '';
    },
    async onSubmit(this: any) {
      this.submiting = true;
      try {
        await axios.put(createApiUrl('/newlinkSass/invitation/create-invitation-user-rel'), this.formData);
      } catch (e) {
        if (e.code === 2335) {
          window.location.href = `/pages/invite-success.html?code=${this.formData.invitationCode}&has-requested=true`;
        } else {
          alert(e.message);
        }
        return;
      } finally {
        this.submiting = false;
      }

      window.location.href = `/pages/invite-success.html?code=${this.formData.invitationCode}`;
    },
    async validatePhone() {
      const res = await validate(this.formData.phone, 'phoneRequired|isMobile');
      if (!res.valid) {
        this.phoneError = res.errors[0];
      } else {
        this.phoneError = '';
      }
    },
    async validateCode() {
      const res = await validate(this.formData.verificationCode, 'codeRequired|isCode');
      if (!res.valid) {
        this.codeError = res.errors[0];
      } else {
        this.codeError = '';
      }
    },
    async onSendCaptcha(this: any) {
      const res = await validate(this.formData.phone, 'phoneRequired|isMobile');
      if (!res.valid) {
        this.phoneError = res.errors[0];
        return;
      }

      if (this.counting || this.codeSending) return;

      this.codeSending = true;
      try {
        await axios.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send/code'), {
          params: { mobile: this.formData.phone, type: 4 },
        });
      } catch (e) {
        return alert(e.message);
      } finally {
        this.codeSending = false;
      }

      this.countStart();
    },
  },
  async mounted(this: any) {
    const up = new URLSearchParams(window.location.search);
    this.formData.invitationCode = up.get('code') || '';
    if (!this.formData.invitationCode) return;
    try {
      await this.loadInviteDetail(this.formData.invitationCode);
    } catch (e) {
      //
      this.inviteDetail.value.state = 'BROKEN';
    }
  },
});
