
import Vue from 'vue';

export default Vue.extend({
  name: 'WebModal',
  props: {
    visible: { type: Boolean, default: true },
  },
  model: { prop: 'visible', event: 'change' },
  data() {
    return {};
  },
  methods: {},
});
