import Vue from 'vue';
const defaultText = '发送验证码'; const resetText = '重新发送'; const countingText = '({num})'; const 
  duration = 60;

export const CountdownMixins = Vue.extend({
  name: '',
  data() {
    return {
      countText: defaultText,
      counting: false,
      countDuration: 0,
      countId: 0,
    };
  },
  methods: {
    countClear(this: any) {
      clearInterval(this.countId);
      this.countDuration = 0;
      this.counting = false;
    },

    countStart(this: any) {
      this.countDuration = duration;
      this.countText = countingText.replace('{num}', this.countDuration.toString());
      this.counting = true;
      this.countId = setInterval(() => {
        this.countDuration--;
        this.countText = countingText.replace('{num}', this.countDuration.toString());
        if (this.countDuration === 0) {
          this.clear();
          this.countText = resetText;
        }
      }, 1000);
    },
  },
});
