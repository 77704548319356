
import Axios, { AxiosError } from 'axios';
import ls from 'nl-illuminate/storage';
import { v4 as uuidV4 } from 'uuid';

const DEVICE_ID = 'DEVICE_ID';
export const CODE_SUCCESS = 200;

export function createApiUrl(path: string) {
  return `${process.env.VUE_APP_API_HOST}${path}`;
}

function getGlobalHeaders() {
  const headers: Record<string, any> = {};
  const deviceId: string = ls.get(DEVICE_ID, uuidV4());
  ls.set(DEVICE_ID, deviceId);

  headers['device-id'] = deviceId;
  headers['device-type'] = 'SAAS';
  headers['device-version'] = process.env.VUE_APP_VERSION;
  headers['app-version'] = process.env.VUE_APP_VERSION;
  headers['app-type'] = 'NLESAAS';
  headers['nl-channel'] = 'web';
  headers.rts = (new Date()).getTime();
  return headers;
}

// 创建 axios 实例
export const axios = Axios.create({
  timeout: 6000, // 请求超时时间
});

// request interceptor
axios.interceptors.request.use(request => {
  const headers = request.headers || {};
  Object.assign(headers, Object.assign({}, getGlobalHeaders(), headers));
  request.headers = headers;
  return request;
}, (error: AxiosError) => Promise.reject(error));

// response interceptor
axios.interceptors.response.use(response => new Promise((resolve, reject) => {
  // 排除操作不成功且后台未返回 msg 情况下可能造成的异常
  if (response.data.code !== CODE_SUCCESS) {
    const e: any = new Error(response.data.msg);
    e.code = response.data.code;
    e.data = response.data.data;
    reject(e);
  } else {
    resolve(response.data);
  }
}), (error: AxiosError) => Promise.reject(error));
